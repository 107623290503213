import React, { Children, useEffect, useState } from 'react';
import { Form, Button, Spinner, Card, Modal, Dropdown, DropdownButton } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import appUrl from '../../appUrl';
import './Table.css';
import '../Modals/Modal.css'
import './Inspection.css';

const Inspection = () => {
  const [submitModel, setSubmitModel] = useState(false)
  const [viewModel, setViewModel] = useState(false)
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showCategories, setShowCategories] = useState(false)
  const [categoriesName, setCategoriesName] = useState([]);
  const [stats, setStats] = useState({
    todayCount: 0,
    upcomingCount: 0,
    completedCount: 0
  });
  const [categoriesList, setCategoriesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [report, setReport] = useState({
    report_file: null,
    report_comment: "",
    report_services: "",
    health_status: "",
    inspectionId: ""
  })
  const [details, setDetails] = useState(null)
  console.log(details, "details")
  console.log(report, "report")
  const AppUrl = appUrl()

  console.log(typeof (report.health_status), "stat")

  const [projects, setProjects] = useState([
    {
      _id: '',
      status: '',
      end_date: '',
      start_date: '',
      clientName: '',
      report_categories: ""
    }
  ]);
  const [pageSize, setPageSize] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');

  console.log(report, "rep")

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const filteredProjects = projects.filter(project => {
    const formattedStatus = project.status === 'active' ? 'pending' : project.status;
    return (
      project.clientName?.toLowerCase().includes(searchText.toLowerCase()) ||
      project.inspection_date?.toLowerCase().includes(searchText.toLowerCase()) ||
      formattedStatus.toLowerCase().includes(searchText.toLowerCase()) ||
      project.action?.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setReport((prevProfile) => ({
      ...prevProfile,
      report_file: file
    }));
  }

  useEffect(() => {
    listProjects()
    dashboard()
  }, [])


  const statusFormatter = (cell, row) => {
    let statusColorClass = '';
    let statusName = ''
    switch (row.status) {
      case 'active':
        statusColorClass = 'in-progress';
        statusName = 'pending'
        break;
      case 'cancelled':
        statusColorClass = 'cancelled';
        statusName = 'cancelled'
        break;
      case 'completed':
        statusColorClass = 'completed';
        statusName = 'completed'
        break;
      default:
        statusColorClass = '';
        statusName = ''
    }
    return <span className={statusColorClass}>{statusName}</span>;
  };


  const listProjects = async () => {
    setLoading(true);
    try {

      const inspectorToken = localStorage.getItem('token');

      const response = await fetch(`${AppUrl}/inspector/getall?page=${currentPage}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${inspectorToken}`,
          'Content-Type': 'application/json',
        }
      });
      const data = await response.json();
      if (data.status === 200) {
        const updatedProjects = data.data.map(project => {
          const clientName = project.userId && project.userId.userInfo ? project.userId.userInfo.first_name : '';
          const reportCategories = project.report && project.report.report_services
            ? project.report.report_services.map(service => service.name).join(', ')
            : '';

          return {
            ...project,
            clientName,
            report_categories: reportCategories,
          };
        });
        setProjects(updatedProjects);
        setPageSize(data.perPage);
        setTotalPages(data.totalPages);
        setTotalCount(data.totalCount);
        setLoading(false)
      } else {
        NotificationManager.error('Failed');
      }
    } catch (error) {
      console.error('Error:', error);
      setLoading(false)
    }
  };

  const Categories = async () => {
    try {
      const inspectorToken = localStorage.getItem('token');

      const response = await fetch(`${AppUrl}/inspector/services`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${inspectorToken}`,
          'Content-Type': 'application/json',
        }
      });
      const data = await response.json();
      if (data.status === 200) {
        setCategoriesList(data.services);
      } else {
        NotificationManager.error(data.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const loadCategories = () => {
    Categories()
    setShowCategories(true)
  }

  const hideCategories = () => {
    setShowCategories(false)
  }

  const dashboard = async () => {
    try {
      const inspectorToken = localStorage.getItem('token');

      const response = await fetch(`${AppUrl}/inspector/stats`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${inspectorToken}`,
          'Content-Type': 'application/json',
        }
      });
      const data = await response.json();
      if (data.status === 200) {
        setStats(data.data);
      } else {
        NotificationManager.error(data.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleCheckboxChange = (categoryId, categoryName) => {
    let newSelectedCategories;
    let newCategoriesName;

    if (selectedCategories.includes(categoryId)) {
      newSelectedCategories = selectedCategories.filter(id => id !== categoryId);
    } else {
      newSelectedCategories = [...selectedCategories, categoryId];
    }
    setSelectedCategories(newSelectedCategories);

    if (categoriesName.includes(categoryName)) {
      newCategoriesName = categoriesName.filter(name => name !== categoryName);
    } else {
      newCategoriesName = [...categoriesName, categoryName];
    }
    setCategoriesName(newCategoriesName);
    setReport({ ...report, report_services: newSelectedCategories.join(', ') });
  };

  const submitReport = async () => {
    setIsLoading(true);
    if (!report.report_file || !report.report_comment || selectedCategories.length === 0 || !report.health_status) {
      NotificationManager.error("Please fill all fields");
      setIsLoading(false);
      return;
    } else {
      try {
        const formData = new FormData();
        if (report.report_file) {
          formData.append('report_file', report.report_file);
        }
        formData.append('report_comment', report.report_comment);
        selectedCategories.forEach((categoryId, index) => {
          formData.append('report_services[]', categoryId);
        });
        formData.append('inspectionId', report.inspectionId);
        formData.append('health_status', report.health_status);
        const agentToken = localStorage.getItem('token');
        const response = await fetch(`${AppUrl}/inspector/submit-report`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${agentToken}`,
          },
          body: formData
        });
        const data = await response.json();
        if (data.status === 200) {
          NotificationManager.success('Report submitted successfully');
          setSelectedCategories('');
          setReport(prevDetails => ({
            ...prevDetails,
            report_file: null,
            report_comment: "",
            report_services: "",
            inspectionId: ""
          }));
          setIsLoading(false);
          setShowCategories(false)
          setSubmitModel(false);
          setCategoriesName([])
          window.location.reload();
        } else {
          NotificationManager.error(data.error);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error:', error);
        setIsLoading(false);
      }
    }
  };


  const actionFormatter = (cell, row) => {
    // Check the status and return the appropriate button
    if (row.status === 'completed') {
      return (
        <Button
          className='Project-Button'
          onClick={() => handleViewReport(row._id)}
        >
          View Report
        </Button>
      );
    } else if (row.status === 'active') {
      return (
        <Button
          className='Project-Button'
          onClick={() => handleSubmitReport(row._id)}>
          Submit Report
        </Button>
      );
    } else {
      return null;
    }
  };

  const handleSubmitReport = (inspectionId) => {
    setReport(prevReport => ({ ...prevReport, inspectionId }));
    setSubmitModel(true);
  };

  const handleViewReport = (id) => {
    const project = projects.find(project => project._id === id);
    if (project) {
      console.log(project);
      setDetails(project);
    }
    setViewModel(true)
  };

  const columns = [
    {
      // formatter: (cell, row, rowIndex) => rowIndex + 1,
      formatter: (cell, row, rowIndex) => pageSize * (currentPage - 1) + rowIndex + 1,
      headerClass: 'serial-number-header ',
      classes: 'serial-number-column',
      dataField: 'index',
      text: 'S No.',
      // filter: textFilter({ placeholder: '' }),
    },
    {
      dataField: 'clientName',
      text: 'Client Name',
      filter: textFilter({ placeholder: '' }),
    },
    {
      dataField: 'inspection_date',
      text: 'Inspection Date',
      filter: textFilter({ placeholder: '' }),
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: statusFormatter,

      filter: textFilter({ placeholder: '' }),
    },
    {
      dataField: 'action',
      text: 'Report',
      formatter: actionFormatter,
      headerClass: 'status-button-header',
      classes: 'status-button-column', // Set minimum width for column cells

    },
  ];

  const cardsData = [
    {
      id: 1,
      title: stats.upcommingCount,
      text: 'Scheduled Inspection',
      bgColor: '#FFDFE2',
      img: '/images/insp1.png',
    },
    {
      id: 2,
      title: stats.todayCount,
      text: 'Today Inspection',
      bgColor: '#E0EFF6',
      img: '/images/insp2.png',
    },
    {
      id: 3,
      title: stats.completedCount,
      text: 'Completed Inspection',
      bgColor: '#FFE1C1',
      img: '/images/insp3.png',
    },
  ];

  const handleDeletePDF = (e) => {
    e.preventDefault()
    setDetails({ ...report, report_file: null });
  };

  const handleDropdownSelect = (e) => {
    setReport((prevReport) => ({
      ...prevReport,
      health_status: e
    }));
  };

  // const handleDeletePDF = (e) => {
  //   e.preventDefault()
  //   // e.stopPropagation();
  //   setDetails({ ...report, report_file: null });
  //   const input = document.getElementById('pdfInput');
  //   input.value = '';
  // };

  return (
    <>
      <div className='inspection'>
        <div className='inspection-row'>
          <div className="d-flex cards mb-4">
            {cardsData.map((card) => (
              <Card
                key={card.id}
                className='inspection-card'
                style={{ width: '18rem', margin: '10px', backgroundColor: card.bgColor }}
              >
                <Card.Body>
                  <Card.Img src={card.img} className='card-img' />
                  <Card.Title>{card.title}</Card.Title>
                  <Card.Text>{card.text}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>

          <div className="show-entries">
            <div className='entries-dropdown'>
              <p className="custom-total">Show entries</p>
              <select value={pageSize} onChange={(e) => setPageSize(parseInt(e.target.value))}>
                {[10, 20, 50, projects.length].map((size, index) => (
                  <option key={index} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <Form.Group controlId="searchInput">
                <Form.Control
                  type="text"
                  placeholder="Search anything here"
                  value={searchText}
                  onChange={handleSearchChange}
                />
              </Form.Group>
            </div>
          </div>

          {loading ? (
            <div className='loading-spinner' >
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <BootstrapTable
              bootstrap4
              keyField="_id"
              data={filteredProjects}
              columns={columns}
              pagination={paginationFactory({
                sizePerPage: pageSize,
                sizePerPageList: [],
                showTotal: true,
                paginationTotalRenderer: (from, to, size) => (
                  <span className="custom-total">
                    Showing {from} to {to} of {size} entries
                  </span>
                ),
              })}
              filter={filterFactory({ placeholder: '' })}
            />
          )}

          <Modal className={`report-modal ${isLoading ? 'blur-effect' : ''}`}
            show={submitModel}
            onHide={() => setSubmitModel(false)}
            onExited={() => {
              setSelectedCategories('');
              setReport(prevDetails => ({
                ...prevDetails,
                report_file: null,
                report_comment: "",
                report_services: "",
                inspectionId: ""
              }));
              setShowCategories(false);
              setCategoriesName([]);
            }}
            backdrop="static"
          >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              <Form >
                <Form.Group className='mb-3' controlId="file">
                  <Form.Label>Upload PDF</Form.Label>
                  <div className='upload-pdf' onClick={() => document.getElementById('pdfInput').click()}>
                    <input
                      type="file"
                      id="pdfInput"
                      accept="application/pdf"
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                    />
                    {report.report_file ? (
                      <div className="image-container">
                        <img src='/images/pdf.png' alt='pdf' />
                        <button className="delete-image-btn" onClick={handleDeletePDF}>✕</button>
                      </div>
                    ) : (
                      <div className='upload-report'>
                        <img src='/images/upload.png' className='mb-2' alt="Upload icon" />
                        <h3>Drag and Drop files, or <span>Browse</span></h3>
                        <p>Upload only a PDF file up to a maximum size of 10MB</p>
                      </div>
                    )}
                  </div>

                  {/* <div className='upload-pdf'>
                        
                            <div onClick={() => document.getElementById('pdfInput').click()}>
                                {report.report_file ? (
                                    <><div className="image-container">
                                        <img src='/images/pdf.png' alt='pdf' /> 
                                        </div>
                                    </>
                                ) : (
                                    <>
                                    <div className='upload-report'>
                                        <img src='/images/upload.png' className='mb-2' alt="Upload" />
                                        <h3 className='mb-2'>Drag and Drop files, or Browse</h3>
                                        <p>Upload only a PDF file up to a maximum size of 10MB</p>
                                        </div>
                                    </>
                                )}
                           
                            {report.report_file && (
                                <button className="delete-image-btn" onClick={handleDeletePDF}>✕</button>
                            )}
                        </div>
                        <input
                            type="file"
                            id="pdfInput"
                            accept="application/pdf"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                        />
                    </div> */}


                </Form.Group>

                <Form.Group controlId="name">
                  <Form.Label>Comments</Form.Label>
                  <Form.Control
                    className='mb-3'
                    as="textarea"
                    rows={5}
                    style={{ resize: "vertical" }}
                    name='report_comment'
                    placeholder="Need to repair your wall, washroom........"
                    value={report.report_comment}
                    onChange={(e) => setReport({ ...report, report_comment: e.target.value })}
                  />
                </Form.Group>
                <Form.Group className='mb-3 position-relative' controlId="option">
                  <Form.Label>Suggested Service Category</Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      as="textarea"
                      placeholder="Select"
                      rows="3"
                      columns="5"
                      value={categoriesName ? categoriesName.join(', ') : ""}
                      className='select-categories'
                      readOnly
                    />
                    <div className='categories-icon position-absolute'>
                      {showCategories ? (<MdOutlineKeyboardArrowUp onClick={hideCategories} />)
                        : (<MdOutlineKeyboardArrowDown onClick={loadCategories} />)
                      }
                    </div>
                  </div>
                </Form.Group>

              </Form>
              {showCategories ? (
                <div className='categories'>
                  <ul>
                    {categoriesList.map((category, id) => (
                      <li key={id}>
                        <div>
                          <img src={category.icon} alt={category.name} />
                          {category.name}
                        </div>
                        <div className='category-select'>
                          <input
                            type='checkbox'
                            onChange={() => handleCheckboxChange(category._id, category.name)}
                            checked={selectedCategories.includes(category._id)}
                          />
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>) : (null)
              }


              <Form >
                <Form.Group className='mb-3' controlId="file">
                  <Form.Label>Home Health</Form.Label>
                  <DropdownButton id="dropdown-basic-button"
                    onSelect={handleDropdownSelect}
                    title={report.health_status || "Select"}
                  >
                    <Dropdown.Item eventKey="poor">Poor</Dropdown.Item>
                    <Dropdown.Item eventKey="good">Good</Dropdown.Item>
                    <Dropdown.Item eventKey="average">Average</Dropdown.Item>
                    <Dropdown.Item eventKey="excellent">Excellent</Dropdown.Item>
                  </DropdownButton>
                </Form.Group>
              </Form>

            </Modal.Body>
            <Modal.Footer>
              <Button className='report-submit-btn' type="submit" onClick={submitReport} disabled={isLoading}>
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className='address-spinner'
                  />
                ) : (
                  <div>Submit </div>
                )}
              </Button>
            </Modal.Footer>
          </Modal>


          <Modal
            className='report-modal'
            show={viewModel}
            onHide={() => setViewModel(false)}
            backdrop="static"
          >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              <h2 className='view-report'>View Your Report</h2>
              <Form>
                <Form.Group className='mb-3' controlId="file">
                  <Form.Label>Uploaded PDF</Form.Label>
                  <a href={details?.report?.report_file} target="_blank" rel="noopener noreferrer">
                    <div className='pdf'>
                      <img src='/images/pdf.png' alt="pdf" className='mb-3' />
                      {details?.report?.report_file || ""}
                    </div>
                  </a>
                </Form.Group>

                <Form.Group controlId="name">
                  <Form.Label>Comments</Form.Label>
                  <div><p className='view-para'>{details?.report?.report_comment || ""}</p></div>
                </Form.Group>
                <Form.Group className='mb-3' controlId="option">
                  <Form.Label>Suggested Service Category</Form.Label>
                  <div><p className='view-para'>{details?.report_categories || ""}</p></div>
                </Form.Group>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <NotificationContainer />
    </>
  );
};

export default Inspection;