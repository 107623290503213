import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route, Navigate,useNavigate } from 'react-router-dom';
import InspectorLogin from './Log/InspectorLog/InspectorLogin';
import InspectorRegister from './Log/InspectorLog/InspectorRegister';
import Inspector from './Pages/Inspector';
import InspectorOTP from './Log/InspectorLog/InspectorOTP';
import { useAuth, AuthProvider } from './Store/auth';
import './App.css'
import InspectorForget from './Log/InspectorLog/InspectorForget';


const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = useAuth();
  return isLoggedIn ? <Component {...rest} /> : <Navigate to="/" />;
};

function App() {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();

  return (
      <Routes>
        {/* <Route path='/' element={<InspectorLogin />} />
        <Route path='/inspector/register' element={<InspectorRegister />} /> */}
        <Route path='/inspector/register'
          element={isLoggedIn ? <Navigate to="/inspector" /> : <InspectorRegister />}
        />
        <Route
          path='/'
          element={isLoggedIn ? <Navigate to="/inspector" /> : <InspectorLogin />}
        />
        <Route path='/inspector/register/otp' element={<InspectorOTP />} />
        <Route path='/inspector/forget-password' element={<InspectorForget />} />
        {/* <Route path='/inspector/*' element={<Inspector />} /> */}
        <Route path='/inspector/*' element={<PrivateRoute component={Inspector}/>} />
      </Routes>
  );
}

export default function WrappedApp() {
  return (
    <AuthProvider>
    <Router>
      <App />
    </Router>
  </AuthProvider>
  );
}
