import React, { useEffect, useState } from 'react';
import appUrl from '../../../src/appUrl';

const Terms = () => {
  const appurl = appUrl();

  const [terms, setTerms] = useState(null);

  const conditions = async () => {
    try {
      const inspectortoken = localStorage.getItem("token");
      const response = await fetch(`${appurl}/inspector/configuration?content_type=termandcondition`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${inspectortoken}`,
        },
      });
      if (response.ok) {
        const responseData = await response.json();
        console.log("terms data:", responseData);

        const data = responseData.data; // Access the 'data' property

        // Remove all HTML tags from the content
        const formattedData = data.map(item => {
          const contentWithoutHTML = item.content.replace(/<[^>]+>/g, '');
          return { ...item, content: contentWithoutHTML }; // Return updated item
        });

        setTerms(formattedData); // Set the formatted data
      }
    } catch (error) {
      console.error("Error fetching About Us data:", error);
    }
  };

  useEffect(() => {
    conditions();
  },[]);

  return (
    <div className='terms'>
      <div>
        <h2 className='mb-4'>Terms and Conditions</h2>
        {terms ? (
          <div>
            {terms.map(item => (
              <p key={item._id}>{item.content}</p>
            ))}
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

export default Terms;

