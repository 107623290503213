import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import '../Register.css';
import OtpInput from 'react-otp-input';
import { useNavigate, useLocation } from 'react-router-dom';
import appUrl from '../../../src/appUrl';

const InspectorOTP = () => {
    const navigate = useNavigate();
    const AppUrl = appUrl();
    const [otp, setOtp] = useState('');
    const [signModal, setSignModal] = useState(false);
    const location = useLocation();
    const { email } = location.state;
    const [resendDisabled, setResendDisabled] = useState(false);
    const [timer, setTimer] = useState(60);

    useEffect(() => {
        let intervalId;
        if (resendDisabled) {
            intervalId = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer === 1) {
                        clearInterval(intervalId);
                        setResendDisabled(false);
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
        return () => clearInterval(intervalId);
    }, [resendDisabled]);

    const sendOTP = async (e) => {
        e.preventDefault();
        try {
            const inspectortoken = localStorage.getItem("token");
            const response = await fetch(`${AppUrl}/inspector/verify-otp`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${inspectortoken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ otp_for_email: otp })
            });
            console.log('Server response:', response);
            console.log(otp);
            const data = await response.json();
            console.log("res for OTP submission", data);
            if (data.status === 200) {
                setSignModal(true);
            } else if (data.status === 400) {
                NotificationManager.error(data.message);
            } else {
                NotificationManager.error('Failed to submit OTP');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const resendOTP = async (e) => {
        e.preventDefault();
        try {
            const inspectortoken = localStorage.getItem("token");
            const response = await fetch(`${AppUrl}/inspector/generate_otp`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${inspectortoken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ source: "2", email_address: email })
            });
            console.log('Server response:', response);
            console.log(otp);
            const data = await response.json();
            console.log("res for OTP submission", data);
            if (data.status === 200) {
                setResendDisabled(true);
                setTimer(60);
            } else if (data.status === 400) {
                NotificationManager.error(data.message);
            } else {
                NotificationManager.error('Failed to submit OTP');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleOkay = () => {
        navigate('/');
    };

    return (
        <>
            <Row className='m-0'>
                <Col className='register otp' lg={6} md={6} sm={12}>
                    <div className="register_form_wrap">
                        <h2>Enter OTP</h2>
                        <p p className='mb-3'>Please enter the 4-digit OTP sent to your E-mail</p>
                        <div className='mb-4 otp'>
                            <OtpInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={4}
                                renderInput={(props) => <input {...props} />}
                                inputStyle={{
                                    width: '60px',
                                    height: '70px',
                                    margin: '0 10px',
                                    padding: '8px',
                                    border: '1px solid #ccc',
                                    backgroundColor: '#fafafa',
                                    color: 'black',
                                    fontSize: '22px',
                                    textAlign: 'center',
                                    outline: 'none',
                                }}
                            />
                        </div>
                        <Button className='verify registerbtn mb-4' onClick={sendOTP} type="submit">Verify</Button>
                        <p className="resend mb-4">
                            <button
                                onClick={resendOTP}
                                disabled={resendDisabled}
                                style={{ color: resendDisabled ? '#555555' : '#FE6F00' }}
                            >
                                Resend OTP  {resendDisabled ? `(${timer}s)` : null}
                            </button>
                        </p>
                    </div>
                </Col>

                <Col className='logo-column' lg={6} md={6} sm={12}>
                    <div className='column-center'>
                        <img src='/images/logo2.png' alt='logo' />
                        <p>bedrock</p>
                    </div>
                </Col>
            </Row >
            <Modal show={signModal} onHide={() => setSignModal(false)} className='agent-modal'>
                <Modal.Body>
                    <img src='/images/checked.png' alt='checked' className='mb-4' />
                    <h2 className='mb-3'>Congratulations!</h2>
                    <p className='mb-4'>Your account has been created <br /> Please wait for admin approval. Once <br />verified,  you'll receive an email</p>
                    <Button className='agent-submit-btn' onClick={handleOkay}>
                        Okay
                    </Button>
                </Modal.Body>
            </Modal>
            <NotificationContainer className="notification-container" />
        </>
    );
};

export default InspectorOTP;
