import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import '../Log.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useNavigate } from 'react-router-dom';
import appUrl from '../../../src/appUrl';
import { useAuth } from '../../Store/auth';
import { requestForToken } from '../../firebase';

const InspectorLogin = ({setIsLoggedIn}) => {
    const navigate = useNavigate();
    const AppUrl = appUrl();
    const { storeTokenInLS } = useAuth();
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [login, setLogin] = useState({
        email_address: '',
        password: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLogin((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleShowPassword = () => {
        setShowPassword(true);
    };

    const handleHidePassword = () => {
        setShowPassword(false);
    };

    const handleLog = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const deviceTokenPromise = requestForToken();
            const deviceToken = await deviceTokenPromise;
            console.log(deviceToken, "device")
            localStorage.setItem("deviceToken",deviceToken)
            const response = await fetch(`${AppUrl}/inspector/login-with-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...login, device_token: deviceToken || "", device_type : "web" }),
            });
            console.log('Server response:', response);
            // debugger
           
            const data = await response.json();
        
            console.log(data);
            if (response.ok && data.status === 200 && data.data.is_active) {
                storeTokenInLS(data.token);
                    setIsLoggedIn(true);
                    setIsLoading(false);
                    console.log("Login successful");
                    console.log("data", data);
                // storeTokenInLS(data.token);
                // console.log("Login successful");
                // localStorage.setItem('isLoggedIn', 'true');
                // // setIsLoggedIn(true)
                // // setIsLoggedIn(true)
                // navigate('/inspector');
            } 
            else if(response.ok && data.status === 204 && !data.is_active){
                navigate('/inspector/register/otp',{ state: { email : login.email_address } });
            }
            else if(data.status === 401){
                NotificationManager.error(data.message)
                setLogin({
                    email_address: '',
                    password: '',
                })
                setIsLoading(false);
            }
            else if(data.status === 400 || data.status === 404 || !login.email_address || !login.password){
                NotificationManager.error(data.message || "Please fill all fields" || 'Invalid Credentials');
                setIsLoading(false);
            }     
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleSignup = () => {
        navigate('/inspector/register');
    };

    const handleForget = () => {
        navigate('/inspector/forget-password');
    };

    return (
        <>
            <NotificationContainer />
            <Row className='m-0'>
                <Col className='login' lg={6} md={6} sm={12}>
                    <div className="login_form_wrap pt-5">
                        <div className='login-head'>
                            <h2 className='mb-3'>Welcome Back</h2>
                            <img src='/images/welcome.png' alt='welcome'></img>
                        </div>
                        <p className='mb-4'>Resume your home’s upgrade journey</p>

                        <Form>
                            <Form.Group controlId="formBasicEmail" className="position-relative mb-3">
                                <i className="fa-solid fa-envelope login_icon_wrap"></i>
                                <Form.Control
                                    type="email"
                                    name='email_address'
                                    placeholder="Email"
                                    value={login.email_address}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword" className="position-relative mb-3">
                                <i className="fa-solid fa-lock login_icon_wrap"></i>
                                <Form.Control
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    placeholder="Password"
                                    value={login.password}
                                    onChange={handleInputChange}
                                />
                                {showPassword ? (
                                    <i className="fa-solid fa-eye password_wrap" onClick={handleHidePassword}></i>
                                ) : (
                                    <i className="fa-solid fa-eye-slash password_wrap"  onClick={handleShowPassword}></i>
                                )}

                            </Form.Group>
                            <p className='forget mb-4' onClick={handleForget}>Forgot password?</p>
                            <Button className='loginbtn mb-3' onClick={handleLog} disabled={isLoading} >Sign in</Button>{' '}
                        </Form>
                        <p className='account'>Don’t have an account? <span onClick={handleSignup}>Sign up</span></p>
                    </div>
                </Col>
                <Col className='logo-column' lg={6} md={6} sm={12}>
                <div className='column-center'>
                        <div><p> <img src='/images/logo2.png' alt='logo' />bedrock</p></div>  
                         <div><h6>Inspector</h6></div>                 
                    </div> 
                </Col>
            </Row>
        </>
    )
}

export default InspectorLogin;
