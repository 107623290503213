import { createContext, useContext, useEffect, useState } from "react";
import appUrl from "../appUrl";

export const AuthContext = createContext();

const appurl = appUrl();

export const AuthProvider = ({ children,navigate }) => {

  const [token,setToken] = useState(localStorage.getItem("token"))  
  const [loggedUser, setLoggedUser] = useState({
    first_name: "",
    last_name:"",
    profile_image: null,
  });
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
    return storedIsLoggedIn ? JSON.parse(storedIsLoggedIn) : false;
  });
  const [sidebar,setSidebar]= useState(true);
  const authorizationToken = `Bearer ${token}`

  const storeTokenInLS = (serverToken) =>{
    localStorage.setItem('token', serverToken);
      setToken(serverToken);
      console.log("Token stored in localStorage:", serverToken);
      setIsLoggedIn(true);
  };
  localStorage.setItem("isLoggedIn",isLoggedIn)
  console.log("loggeddata",loggedUser)
  console.log(token,"whywhy")

  const LogoutUser =()=>{
    setIsLoggedIn(false);
    localStorage.removeItem("token"); 
    setToken("");
  }

  //jwt authentication- to get currently logged in user data
  const userAuthentication = async () => {
    try {
      const response = await fetch(`${appurl}/agent/user-profile`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }); 
       const data = await response.json();
      if (response.ok && data.status === 200) {       
        console.log("user data", data.user);
        setLoggedUser(data.user);
        setIsLoggedIn(true);
      }
      else if (data.status === 401) {
        setIsLoggedIn(false);
        localStorage.clear();
        setToken(""); 
        navigate("/inspector")
        window.location.reload()
      }
    } catch (error) {
      console.error("Error fetching user data", error);
    }
  };
  
  useEffect(()=>{
    userAuthentication();
  },[token])

    return (
    <AuthContext.Provider value={{ isLoggedIn ,storeTokenInLS, LogoutUser, loggedUser, setLoggedUser,authorizationToken,sidebar,setSidebar}}>
        {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
    const authContext = useContext(AuthContext);
  
    if (!authContext) {
      throw new Error("useAuth must be used within an AuthProvider");
    }
  
    return authContext;
  };