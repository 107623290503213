import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import appUrl from '../../../src/appUrl';
import { useAuth } from '../../Store/auth';
import PasswordChecklistComponent from './PasswordChecklist';
import {requestForToken} from "../../firebase"
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const InspectorRegister = () => {
    const navigate = useNavigate();
    const AppUrl = appUrl();
    const { storeTokenInLS } = useAuth();
    requestForToken()

    const [inspector, setInspector] = useState({
        first_name: '',
        last_name: '',
        email_address: '',
        phone_number: '',
        password: '',
        confirm_password: '',
        checkbox: false,
        user_type: "inspector",
        device_type: "web",
        // device_token: "netscape"
    });
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tokenFetched, setTokenFetched] = useState(false);
    

    const handleShowPassword = () => setShowPassword(true);
    const handleHidePassword = () => setShowPassword(false);
    const handleShowConfirmPassword = () => setShowConfirmPassword(true);
    const handleHideConfirmPassword = () => setShowConfirmPassword(false);

    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
        setInspector((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? e.target.checked : value,
        }));
    };

    const handleLogin = () => {
        navigate('/');
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); 
        setIsLoading(true);
        if (inspector.password !== inspector.confirm_password) {
            NotificationManager.error("Passwords do not match");
            setIsLoading(false);
            return;
        }
        
        else if (
            !(inspector.password.length >= 8 && // Check minimum length
            /[!@#$%^&*(),.?":{}|<>]/.test(inspector.password) && // Check for special characters
            /\d/.test(inspector.password) && // Check for numbers
            /[A-Z]/.test(inspector.password) && // Check for capital letters
            /[a-z]/.test(inspector.password))
        ) {
            NotificationManager.error("Please ensure the password meets the required criteria");
        } else{
        try {
            // Request for token asynchronously
            const deviceTokenPromise = requestForToken();
            const deviceToken = await deviceTokenPromise; // Wait for the token to be resolved
            const response = await fetch(`${AppUrl}/inspector/signup`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ ...inspector,device_token: deviceToken || "" 
                }),
            });
            const data = await response.json();
            if (response.ok && data.status === 200) {
                // storeTokenInLS(data.token);
                localStorage.setItem("token",data.token)
                navigate('/inspector/register/otp', { state: { 
                    email: inspector.email_address} });
            } else if (data.status === 400) {
                NotificationManager.error("email already exist");
            }  else if (data.status === 403) {
                NotificationManager.error(data.message);
            } else {
                NotificationManager.error('Failed to submit form. Please fill all fields.');
            }
        }  catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
        }
    }};

    return (
        <>
            <NotificationContainer />
            
            <Row className='m-0'>
                <Col className='register' lg={6} md={6} sm={12}>
                    <div className="register_form_wrap">
                        <h2 className='mb-2'>Create an account</h2>
                        <p className='mb-3'>All fields are required</p>
                        <Form onSubmit={handleSubmit}
                        //</div>autoComplete='off'
                        >
                            <Form.Group controlId="validationFormikFirstName" className="position-relative mb-3" required>
                                <i className="fa-solid fa-user login_icon_wrap"></i>
                                <Form.Control
                                    type="text"
                                    placeholder="First Name"
                                    name="first_name"
                                    value={inspector.first_name}
                                    onChange={handleInputChange}
                                    autoComplete='off' />
                            </Form.Group>

                            <Form.Group controlId="validationFormikLastName" className="position-relative mb-3" required>
                                <i className="fa-solid fa-user login_icon_wrap"></i>
                                <Form.Control
                                    type="text"
                                    placeholder="Last Name"
                                    name="last_name"
                                    value={inspector.last_name}
                                    onChange={handleInputChange}
                                    autoComplete='off' />
                            </Form.Group>

                            <Form.Group controlId="validationFormikEmail" className="position-relative mb-3" required>
                                <i className="fa-solid fa-envelope login_icon_wrap"></i>
                                <Form.Control
                                    type="email"
                                    placeholder="E-mail"
                                    name="email_address"
                                    value={inspector.email_address}
                                    onChange={handleInputChange} />
                            </Form.Group>

                            <Form.Group controlId="validationFormikPhoneNumber" className="position-relative mb-3" required>
                                <i className="fa-solid fa-phone login_icon_wrap"></i>
                                <Form.Control
                                    type="number"
                                    placeholder="Phone Number"
                                    name="phone_number"
                                    value={inspector.phone_number}
                                    onChange={handleInputChange}
                                    autoComplete='off' />
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword"
                                className="position-relative mb-3">
                                <i class="fa-solid fa-lock login_icon_wrap"></i>
                                <Form.Control
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    placeholder="Password"
                                    value={inspector.password}
                                    onChange={handleInputChange}
                                    autoComplete='off'
                                />

                                {
                                    inspector.password && inspector.password.length > 0 ?
                                        <PasswordChecklistComponent
                                            password={inspector.password || ""}
                                            rules={["minLength", "specialChar", "number", "capital", "lowercase"]}
                                        /> : ``
                                }

                                {showPassword ? (
                                    <i className="fa-solid fa-eye password_wrap" onClick={handleHidePassword}></i>
                                ) : (
                                    <i className="fa-solid fa-eye-slash password_wrap" onClick={handleShowPassword}></i>
                                )}

                            </Form.Group>
                            <Form.Group controlId="formBasicPassword"
                                className="position-relative mb-3">
                                <i class="fa-solid fa-lock login_icon_wrap"></i>
                                <Form.Control
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    name="confirm_password"
                                    placeholder="Confirm Password"
                                    value={inspector.confirm_password}
                                    onChange={handleInputChange}
                                    autoComplete='off'
                                />
                                {showConfirmPassword ? (
                                    <i className="fa-solid fa-eye password_wrap" onClick={handleHideConfirmPassword}></i>
                                ) : (
                                    <i className="fa-solid fa-eye-slash password_wrap" onClick={handleShowConfirmPassword}></i>
                                )}

                            </Form.Group>

                            <label className='registeraccount mb-3'>
                                <input type="checkbox" id="myCheckbox" required checked={inspector.checkbox} onChange={handleInputChange} name="checkbox" /> I have read and agree to the <span>Privacy Policy</span> and <br />
                                <span>Terms and Conditions</span>
                            </label>

                            <Button type="submit" className='registerbtn mb-3' disabled={isLoading}>Sign up</Button>
                            <p className='already'>Already have an account? <span onClick={handleLogin}>Sign in</span></p>

                        </Form>
                    </div>
                </Col>
                <Col className='logo-column' lg={6} md={6} sm={12}>
                <div className='column-center'>
                        <div><p> <img src='/images/logo2.png' alt='logo' />bedrock</p></div>  
                         <div><h6>Inspector</h6></div>                 
                    </div> 
                </Col>
            </Row>
        </>
    );
};

export default InspectorRegister;
