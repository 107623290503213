import React from 'react'
import PasswordChecklist from "react-password-checklist";
import "../Register.css"

export default function PasswordChecklistComponent({
    password,
    // confirm_password,
    rules
}) 

{
    return (
        <div className="main_wrapper">
            <PasswordChecklist
                rules= {rules}
                minLength={8}
                value={password}
                
                // valueAgain={confirm_password}
                messages={{
                    minLength: "Password has minimum 8 character.",
                    specialChar: "Password has special character.",
                    number: "Password has number.",
                    capital: "Password has a capital letter.",
                    lowercase: "Password has small letter",
                    // match: "Password matched"
                }}
            />
        </div>
    )
}