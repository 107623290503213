import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import '../Register.css';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import appUrl from '../../appUrl';
import PasswordChecklistComponent from './PasswordChecklist';

const InspectorForget = () => {
    const appurl = appUrl();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [change, setChange] = useState({
        newPassword: "",
        confirm_password: "",
    });
    const [sendEmail, setSendEmail] = useState(true);
    const [verify, setVerify] = useState(false);
    const [newPassword, setNewPassword] = useState(false);
    const [resendDisabled, setResendDisabled] = useState(false);
    const [timer, setTimer] = useState(60);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleShowPassword = () => {
        setShowPassword(true);
    }

    const handleHidePassword = () => {
        setShowPassword(false)
    }

    const handleShowConfirmPassword = () => {
        setShowConfirmPassword(true);
    }

    const handleHideConfirmPassword = () => {
        setShowConfirmPassword(false)
    }

    useEffect(() => {
        let intervalId;
        if (resendDisabled) {
            intervalId = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer === 1) {
                        clearInterval(intervalId);
                        setResendDisabled(false);
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
        return () => clearInterval(intervalId);
    }, [resendDisabled]);

    useEffect(() => {
        let intervalId;
        if (resendDisabled) {
            intervalId = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer === 1) {
                        clearInterval(intervalId);
                        setResendDisabled(false);
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
        return () => clearInterval(intervalId);
    }, [resendDisabled]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setChange((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const sendOTP = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${appurl}/inspector/forget-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email_address: email })
            });
            const data = await response.json();
            if (data.status === 200) {
                NotificationManager.success(data.message);
                setSendEmail(false);
                setVerify(true);
                setResendDisabled(true);
                setTimer(60);
            }
            else if (data.status === 400) {
                NotificationManager.error(data.message);
            }
            else {
                NotificationManager.error('Failed to submit Email');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const verifyOTP = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${appurl}/inspector/verify-forget-password-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email_address: email, otp: otp })
            });
            const data = await response.json();
            if (data.status === 200) {
                setVerify(false);
                setNewPassword(true);
            }

            else if (data.status === 400) {
                NotificationManager.error(data.message);
            }
            else {
                NotificationManager.error(data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const changePassword = async (e) => {
        e.preventDefault();
        if (change.newPassword !== change.confirm_password) {
            NotificationManager.error("Passwords do not match");
        }
        else {
            try {
                const response = await fetch(`${appurl}/inspector/update-password`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email_address: email, newPassword: change.newPassword })
                });
                const data = await response.json();
                if (change.newPassword !== change.confirm_password) {
                    NotificationManager.error('Passwords do not match');
                }
                else if (
                    !(change.newPassword.length >= 8 && // Check minimum length
                        /[!@#$%^&*(),.?":{}|<>]/.test(change.newPassword) && // Check for special characters
                        /\d/.test(change.newPassword) && // Check for numbers
                        /[A-Z]/.test(change.newPassword) && // Check for capital letters
                        /[a-z]/.test(change.newPassword))
                ) {
                    NotificationManager.error("Please ensure the password meets the required criteria");
                }
                else if (data.status === 200) {
                    setChange(false);
                    navigate('/');
                }
                else if (data.status === 400) {
                    NotificationManager.error(data.message);
                }
                else {
                    NotificationManager.error('Failed to submit Email');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    return (
        <>
            {sendEmail && (
                <Row className='m-0'>
                    <Col className='register' lg={6} md={6} sm={12}>
                        <div className='register_form_wrap pt-5'>
                            <h1 className='mb-4'>Enter Your Email</h1>
                            <Form.Group controlId="validationFormikEmail" className="position-relative mb-5">
                                <i className="fa-solid fa-envelope login_icon_wrap"></i>
                                <Form.Control
                                    type="email"
                                    placeholder="E-mail"
                                    name="emailaddress"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Button className='registerbtn mb-3' onClick={sendOTP} type="submit">Send OTP</Button>
                        </div>
                    </Col>

                    <Col className='logo-column' lg={6} md={6} sm={12}>
                    <div className='column-center'>
                        <div><p> <img src='/images/logo2.png' alt='logo' />bedrock</p></div>  
                         <div><h6>Inspector</h6></div>                 
                    </div> 
                    </Col>
                </Row>
            )}
            {verify && (
                <Row className='m-0'>
                    <Col className='register' lg={6} md={6} sm={12}>
                        <div className='register_form_wrap pt-5'>
                            <h1 className='mb-4'>Enter OTP</h1>
                            <p className='mb-4'>Please enter the 4-digit OTP sent to your E-mail</p>
                            <div className='mb-4 otp'>
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    renderInput={(props) => <input {...props} />}
                                    inputStyle={{
                                        width: '60px',
                                        height: '70px',
                                        margin: '0 10px',
                                        padding: '8px',
                                        border: '1px solid #ccc',
                                        backgroundColor: '#fafafa',
                                        color: 'black',
                                        fontSize: '22px',
                                        textAlign: 'center',
                                        outline: 'none',
                                    }}
                                />
                            </div>
                            <Button className='registerbtn mb-4' onClick={verifyOTP} type="submit">Verify</Button>
                            <p className="resend mb-4">
                                <button
                                    onClick={sendOTP}
                                    disabled={resendDisabled}
                                    style={{ color: resendDisabled ? '#555555' : '#FE6F00' }}
                                >
                                    Resend OTP  {resendDisabled ? `(${timer}s)` : null}
                                </button>
                            </p>
                        </div>
                    </Col>

                    <Col className='logo-column' lg={6} md={6} sm={12}>
                    <div className='column-center'>
                        <div><p> <img src='/images/logo2.png' alt='logo' />bedrock</p></div>  
                         <div><h6>Inspector</h6></div>                 
                    </div> 
                    </Col>
                </Row>
            )}
            {newPassword && (
                <Row className='m-0'>
                    <Col className='register' lg={6} md={6} sm={12}>
                        <div className='register_form_wrap pt-5'>
                            <h1 className='mb-4'>Change Password</h1>
                            <Form.Group controlId="formNewPassword"
                                className="position-relative mb-4">
                                <i class="fa-solid fa-lock login_icon_wrap"></i>
                                <Form.Control
                                    type="text"
                                    name="newPassword"
                                    placeholder="New Password"
                                    value={change.newPassword}
                                    onChange={handleInputChange}
                                />
                                  {showPassword ? (
                                    <i className="fa-solid fa-eye password_wrap" onClick={handleHidePassword}></i>
                                ) : (
                                    <i className="fa-solid fa-eye-slash password_wrap" onClick={handleShowPassword}></i>
                                )}
                                 {
                                    change.newPassword && change.newPassword.length > 0 ?
                                        <PasswordChecklistComponent
                                            password={change.newPassword || ""}
                                            rules={["minLength", "specialChar", "number", "capital", "lowercase"]}
                                        /> : ``
                                }
                            </Form.Group>

                            <Form.Group controlId="formConfirmPassword"
                                className="position-relative mb-4">
                                <i class="fa-solid fa-lock login_icon_wrap"></i>
                                <Form.Control
                                    type="text"
                                    name="confirm_password"
                                    placeholder="Confirm Password"
                                    value={change.confirm_password}
                                    onChange={handleInputChange}
                                />
                                  {showConfirmPassword ? (
                                    <i className="fa-solid fa-eye password_wrap" onClick={handleHideConfirmPassword}></i>
                                ) : (
                                    <i className="fa-solid fa-eye-slash password_wrap" onClick={handleShowConfirmPassword}></i>
                                )}
                            </Form.Group>

                            <Button className='registerbtn mb-3' onClick={changePassword} type="submit">Change Password</Button>
                        </div>
                    </Col>

                    <Col className='logo-column' lg={6} md={6} sm={12}>
                    <div className='column-center'>
                        <div><p> <img src='/images/logo2.png' alt='logo' />bedrock</p></div>  
                         <div><h6>Inspector</h6></div>                 
                    </div> 
                    </Col>
                </Row>
            )}
            <NotificationContainer className="notification-container" />
        </>
    );
};

export default InspectorForget;
