import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import appUrl from '../../../appUrl';
import 'react-toastify/dist/ReactToastify.css';
import './Password.css'

const Password = () => {
    const appurl = appUrl();
    const [change, setChange] = useState({
        newPassword: "",
        confirm_password: "",
    })
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleShowPassword = () => {
        setShowPassword(true);
    }

    const handleHidePassword = () => {
        setShowPassword(false)
    }

    const handleShowNewPassword = () => {
        setShowNewPassword(true);
    }

    const handleHideNewPassword = () => {
        setShowNewPassword(false)
    }


    const handleShowConfirmPassword = () => {
        setShowConfirmPassword(true);
    }

    const handleHideConfirmPassword = () => {
        setShowConfirmPassword(false)
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setChange((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const changePassword = async (e) => {
        e.preventDefault();
        if (change.newPassword !== change.confirm_password) {
            toast.error("Passwords do not match")
        }
        else {
            try {
                const inspectorToken = localStorage.getItem("token")
                const response = await fetch(`${appurl}/inspector/change-password`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${inspectorToken}`,
                    },
                    body: JSON.stringify({
                        new_password: change.newPassword,
                        old_password: password,
                    })
                });
                console.log('Server response:', response);
                const data = await response.json();
                console.log("res for OTP submission", data)
                if (data.status === 200) {
                    toast.success(data.message)
                    setPassword('')
                    setChange(
                       { newPassword: "",
                        confirm_password: "",}
                    )
                }
                else if (data.status === 400) {
                    toast.error(data.message)
                }
                else {
                    toast.error('Failed to submit Email');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };
    return (
        <div className='pass'>
            <Form className='changepass'>
                <h2>Change Password</h2>
                <Form.Group className='position-relative mb-4' controlId="formOldPassword">
                <i class="fa-solid fa-lock password_icon_wrap"></i>
                    <Form.Control
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Current Password"
                        value={password}
                        onChange={(e) => (setPassword(e.target.value))}
                    />
                      {showPassword ? (
                        <i className="fa-solid fa-eye change-password_wrap" onClick={handleHidePassword}></i>
                    ) : (
                        <i className="fa-solid fa-eye-slash change-password_wrap" onClick={handleShowPassword}></i>
                    )}
                </Form.Group>
                <Form.Group  className="position-relative mb-4" controlId="formNewPassword">
                    <i class="fa-solid fa-lock password_icon_wrap"></i>
                    <Form.Control
                        name='newPassword'
                        type={showNewPassword ? 'text' : 'password'}
                        placeholder="New Password"
                        value={change.newPassword}
                        onChange={handleInputChange}
                    />
                    {showNewPassword ? (
                        <i className="fa-solid fa-eye change-password_wrap" onClick={handleHideNewPassword}></i>
                    ) : (
                        <i className="fa-solid fa-eye-slash change-password_wrap" onClick={handleShowNewPassword}></i>
                    )}
                </Form.Group>

                <Form.Group  className="position-relative mb-5" controlId="formNewPassword">
                    <i class="fa-solid fa-lock password_icon_wrap"></i>
                    <Form.Control
                        name='confirm_password'
                        type={showConfirmPassword ? 'text' : 'password'}
                        placeholder="New Password"
                        value={change.confirm_password}
                        onChange={handleInputChange}
                    />
                    {showConfirmPassword ? (
                        <i className="fa-solid fa-eye change-password_wrap" onClick={handleHideConfirmPassword}></i>
                    ) : (
                        <i className="fa-solid fa-eye-slash change-password_wrap" onClick={handleShowConfirmPassword}></i>
                    )}
                </Form.Group>
                <div className='passbutton mb-5'>
                    <Button className='passbtn'
                        onClick={changePassword}>Update password
                    </Button>{' '}</div>
            </Form>
        </div>
    )
}

export default Password
