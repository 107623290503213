import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyDtUR0XMPK_K-8zT1WCWD4Pi37jA3e1bwc",
    authDomain: "bedrock-insepctor.netscapelabs.com",
    projectId: "bedrock-homeowner",
    storageBucket: "bedrock-homeowner.appspot.com",
    messagingSenderId: "310462268096",
    appId: "1:310462268096:web:539b062e164ac6147fa325",
    measurementId: "G-G70WS17JP7"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

// Cache to store notification IDs that have been shown
const shownNotifications = new Set();

export const requestForToken = () => {
  return getToken(messaging, { 
    vapidKey: "BIasBulul9pvTNCX_YP58cKTyOYke6ICwSSiLZ66YGuA5Etam33zX3zo2aTZ0Qr86-du33JExJiyHnsil3ZmWcQ"})
      .then((currentToken) => {
          if (currentToken) {
              console.log('current token for client: ', currentToken);
              return currentToken;
          } else {
              console.log('No registration token available. Request permission to generate one.');
              return null;
          }
      })
      .catch((err) => {
          console.log('An error occurred while retrieving token: ', err);
          return null;
      });
};

// Listen for messages in the background
onMessage(messaging, (payload) => {
    console.log('Message received (background):', payload);
    // Check if the notification is related to your userId
    if (payload.data && payload.data.userId === localStorage.getItem("userId")) {
        // Check if the notification ID has been shown already
        if (!shownNotifications.has(payload.data.notificationId)) {
            // Add the notification ID to the cache
            shownNotifications.add(payload.data.notificationId);

            // Process the notification or show it to the user
            const notification = new Notification(payload.notification.title, {
                body: payload.notification.body,
                icon: payload.notification.icon || '/images/default-icon.png',
                // other options
            });

            // Handle notification click
            notification.onclick = (event) => {
                event.preventDefault();
                // Handle the click action here
                console.log('Notification clicked:', payload);
            };
        }
    }
});

// Service worker registration code (if not already registered)
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/firebase-messaging-sw.js')
            .then(registration => {
                console.log('Service Worker registered with scope:', registration.scope);
            })
            .catch(error => {
                console.error('Service Worker registration failed:', error);
            });
    });
}

initializeApp(firebaseConfig);
