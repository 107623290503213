import React, { useEffect, useState } from 'react';
import appUrl from '../../../src/appUrl';

const Privacy = () => {
  const appurl = appUrl();

  const [privacy, setPrivacy] = useState(null);

  const privacyPolicy = async () => {
    try {
      const inspectortoken = localStorage.getItem("token");
      const response = await fetch(`${appurl}/inspector/configuration?content_type=privacypolicy`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${inspectortoken}`,
        },
      });
      if (response.ok) {
        const responseData = await response.json();
        console.log("Privacy data:", responseData);

        const data = responseData.data; // Access the 'data' property

        // Remove all HTML tags from the content
        const formattedData = data.map(item => {
          const contentWithoutHTML = item.content.replace(/<[^>]+>/g, '');
          return { ...item, content: contentWithoutHTML }; // Return updated item
        });

        setPrivacy(formattedData); // Set the formatted data
      }
    } catch (error) {
      console.error("Error fetching About Us data:", error);
    }
  };

  useEffect(() => {
    privacyPolicy();
  });

  return (
    <div className='terms'>
      <div>
        <h2 className='mb-4'>Privacy</h2>
        {privacy ? (
          <div>
            {privacy.map(item => (
              <p key={item._id}>{item.content}</p>
            ))}
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
    // "privacyPolicy", "privacypolicy", "helpcenter", "termandcondition"   for one category send this otherwise its optional
  );
};

export default Privacy;
