import React, { useState, useEffect } from 'react';
import './Sidebar.css';
import { MdLockOutline, MdOutlinePrivacyTip } from "react-icons/md";
import { FiFileText } from "react-icons/fi";
import { IoHelpCircleOutline } from "react-icons/io5"
import { BsFileEarmarkText } from "react-icons/bs";
import { RxText } from "react-icons/rx";
import { Link } from 'react-router-dom';
import { useAuth } from '../../Store/auth'
import { useNavigate } from 'react-router-dom';
import { RiArrowLeftDoubleLine } from "react-icons/ri";

const InspectionSidebar = ({ onItemClick }) => {
  const navigate = useNavigate();
  const { setSidebar } = useAuth();
  const [activeItem, setActiveItem] = useState("inspection");

  const handleItemClick = (item) => {
    onItemClick(item);
    setActiveItem(item);
  };

  const clickSidebar = () => {
    setSidebar(false)
  }

  return (
    <>
      <div className="sidebar">
        <div className='sidebar-head'>
          <div className="d-flex">
            <img src='/images/Logo.png' alt='Logo' />
            <h2>bedrock</h2>
          </div>
          <RiArrowLeftDoubleLine className="arrow-icon" onClick={clickSidebar} />
        </div>
        <ul>
        <Link to="/inspector/dashboard">
          <li className={activeItem === 'inspection' ? 'active' : ''} onClick={() => handleItemClick('inspection')}>
            <FiFileText />
            My Inspection Logs
          </li>
        </Link>
        <Link to="/inspector/password">
          <li className={activeItem === 'password' ? 'active' : ''} onClick={() => handleItemClick('password')}>
            <MdLockOutline />
            Change Password
          </li>
        </Link>
        <Link to="/inspector/aboutus">
          <li className={activeItem === 'aboutus' ? 'active' : ''} onClick={() => handleItemClick('aboutus')}>
            <BsFileEarmarkText />
            About Us
          </li>
        </Link>
        <Link to="/inspector/privacy">
          <li className={activeItem === 'privacy' ? 'active' : ''} onClick={() => handleItemClick('privacy')}>
            <MdOutlinePrivacyTip />
            Privacy policy
          </li>
        </Link>
        <Link to="/inspector/terms">
          <li className={activeItem === 'terms' ? 'active' : ''} onClick={() => handleItemClick('terms')}>
            <RxText />
            Terms and Conditions
          </li>
        </Link>
        <Link to="/inspector/support">
          <li className={activeItem === 'support' ? 'active' : ''} onClick={() => handleItemClick('support')}>
            <IoHelpCircleOutline />
            Support & Help Center
          </li>
        </Link>
      </ul>
      </div>
    </>
  );

};

export default InspectionSidebar;