import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { IoMdArrowBack } from "react-icons/io";
import './Password/Password.css';
import { useAuth } from '../../Store/auth';
import appUrl from '../../appUrl';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useNavigate } from 'react-router-dom';
import { LuImagePlus } from "react-icons/lu";

const EditProfile = () => {
    const { loggedUser, setLoggedUser } = useAuth();
    const appurl = appUrl();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const [profile, setProfile] = useState({
        first_name: '',
        last_name: '',
        profile_image: null
    });

    useEffect(() => {
        if (loggedUser) {
            setProfile({
                first_name: loggedUser.first_name,
                last_name: loggedUser.last_name,
                profile_image: loggedUser.profile_image
            });
        }
    }, [loggedUser]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfile((prevProfile) => ({
            ...prevProfile,
            [name]: value,
        }));
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setProfile((prevProfile) => ({
            ...prevProfile,
            profile_image: file
        }));
    }

    const updateProfile = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const userToken = localStorage.getItem('token');
            const formData = new FormData();
            formData.append('first_name', profile.first_name);
            formData.append('last_name', profile.last_name);
            if (profile.profile_image) {
                formData.append('profile_image', profile.profile_image);
            }
            const response = await fetch(`${appurl}/inspector/update-profile`, {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
                body: formData
            });

            const data = await response.json();
            console.log("server data", data);
            if (response.ok && data.status === 200) {
                NotificationManager.success(data.message);
                setLoggedUser({ ...profile }); // Update loggedUser with edited profile data
                console.log("user data", { ...profile });
                setIsLoading(false);
            } else if (data.status === 404) {
                NotificationManager.error(data.message);
                setIsLoading(false);
            } else {
                NotificationManager.error(data.message);
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error fetching user data", error);
        }
    };


    return (
        <>
            <div className='enter-address'>
                <div className='enter-head mb-4'>
                    {/* <IoMdArrowBack /> */}
                    <h5>Edit Profile</h5>
                    {/* <p></p> */}
                </div>
                <div className='upload mb-4'>
                    <div className='profile-image'>
                        <div className='profile-image' onClick={() => document.getElementById('imageInput').click()}>
                                {profile.profile_image && profile.profile_image instanceof File ? (
                                    <img src={URL.createObjectURL(profile.profile_image)} alt="profile" />
                                ) : (
                                    profile.profile_image ? (
                                        <img src={profile.profile_image} alt="profile" />
                                    ) : (
                                        <LuImagePlus />
                                    )
                                )}
                            <input
                                type="file"
                                id="imageInput"
                                accept="image/*"
                                onChange={handleImageChange}
                                style={{ display: 'none' }}
                            />
                        </div>
                    </div>
                </div>

                <Form className='address-details mb-3'>
                    <Form.Group className='mb-4' controlId="formFirstName" required>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            type='text'
                            name="first_name"
                            value={profile.first_name}
                            onChange={handleInputChange} />
                    </Form.Group>

                    <Form.Group className='mb-4' controlId="formLastName" required>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            type='text'
                            name="last_name"
                            value={profile.last_name}
                            onChange={handleInputChange} />
                    </Form.Group>

                    <Form.Group className="position-relative mb-4" controlId="FormEmail" required>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            name="email_address"
                            value={loggedUser.email_address}
                            disabled
                        />
                        {/* <p className='verify-profile'>Change Email</p> */}
                    </Form.Group>

                    <Form.Group className="position-relative mb-5" controlId="FormPhone"
                        required>
                        <Form.Label>Mobile Number</Form.Label>
                        <Form.Control
                            type="number"
                            name="phone_number"
                            value={loggedUser.phone_number}
                            disabled
                        />
                        {/* <p className='verify-profile'>Change Number</p> */}
                    </Form.Group>


                    <div className='addbutton'><Button className='add-btn' type="submit" onClick={updateProfile} disabled={isLoading}>Update</Button>{' '}</div>
                </Form>
            </div>
            <NotificationContainer />
        </>
    )
}

export default EditProfile;
