import React, { useState } from 'react';
import { Row, Col, Dropdown, Modal, Button, Form } from 'react-bootstrap';
import InspectionSidebar from '../components/Sidebar/InspectorSidebar';
import Inspection from '../components/Inspection/Inspection';
import Password from '../components/Inspection/Password/Password';
import { useAuth } from '../Store/auth';
import EditProfile from '../components/Inspection/EditProfile';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../src/components/Sidebar/Sidebar.css'
import "../../src/components/Modals/Modal.css"
import { RiArrowRightDoubleLine } from "react-icons/ri";
import appUrl from '../appUrl';
import AboutUs from '../components/Inspection/AboutUs';
import Privacy from '../components/Inspection/Privacy';
import Terms from '../components/Inspection/Terms';
import Support from '../components/Inspection/Support';

const Inspector = () => {
  const [selectedItem, setSelectedItem] = useState("inspection");
  const [logoutModal, setLogoutModal] = useState(false)
  const [deleteAccount, setDeleteAccount] = useState()
  const { loggedUser, LogoutUser, sidebar, setSidebar } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const AppUrl = appUrl();
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(true);
  }

  const handleHidePassword = () => {
    setShowPassword(false)
  }

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };
  const handleProfile = () => {
    setSelectedItem('editProfile')
  }

  const logoutUser = async (e) => {
    setIsLoading(true)
    try {
      const inspectortoken = localStorage.getItem("token")
      const device_token = localStorage.getItem("deviceToken")
      const response = await fetch(`${AppUrl}/inspector/logout`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${inspectortoken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ device_type: 'web', device_token: device_token })
      });
      console.log('Server response:', response);
      const data = await response.json();
      console.log(data)
      if (response.ok && data.status === 200) {
        // navigate('/')
        // localStorage.removeItem("token")
        localStorage.removeItem("token")
        LogoutUser()
        navigate("/");
        setIsLoading(false)
      }
      else {
        toast.error(data.message)
        setIsLoading(false)
      }
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false)
    }
  };

  const DeleteUser = async (e) => {
    setIsLoading(true)
    try {
      const agenttoken = localStorage.getItem("token")
      const response = await fetch(`${AppUrl}/inspector/account-delete`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${agenttoken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password: password })
      });
      console.log('Server response:', response);
      const data = await response.json();
      console.log(data)
      if (response.ok && data.status === 200) {
        localStorage.removeItem("token")
        LogoutUser()
        navigate("/");
        setIsLoading(false)
      }
      else {
        toast.error(data.message)
        setIsLoading(false)
      }
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false)
    }
  };

  return (
    <>
      <Row className='m-0'>
        {/* <Col className='side' lg={2} md={2}>
          <InspectionSidebar onItemClick={handleItemClick} />
        </Col>
        <Col className='tabs' lg={10} md={10}> */}
        <Col className={`side ${sidebar ? "col_1_open" : "col_1_close"}`} lg={3} md={3}>
          {sidebar ?
            <InspectionSidebar onItemClick={handleItemClick} /> :
            <RiArrowRightDoubleLine className='arrow-icon' onClick={() => setSidebar(true)} />
          }
        </Col>
        <Col className={`tabs ${sidebar ? "col_2_open" : "col_2_close"}`} lg={9} md={9}>
          <div className='bellicon'>
            <div className='user-profile'>
              {loggedUser.profile_image ?
                (<img src={loggedUser.profile_image} alt="image" />)
                : <img src='/images/dummy.jpg' />}
            </div>
            <Dropdown className='user'>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                {loggedUser.first_name} {loggedUser.last_name}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleProfile}>Edit Profile</Dropdown.Item>
                <Dropdown.Item onClick={() => setLogoutModal(true)}>Logout</Dropdown.Item>
                <Dropdown.Item onClick={() => setDeleteAccount(true)}>Delete Account</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div><img src='/images/bell.png' alt='bell' /></div>
          </div>
          <div className='pages'>
            {selectedItem === 'inspection' && <Inspection />}
            {selectedItem === 'password' && <Password />}
            {selectedItem === 'editProfile' && <EditProfile />}
            {selectedItem === 'aboutus' && <AboutUs />}
            {selectedItem === 'privacy' && <Privacy />}
            {selectedItem === 'terms' && <Terms />}
            {selectedItem === 'support' && <Support />}
          </div>
        </Col>
      </Row>

      <Modal show={logoutModal} onHide={() => setLogoutModal(false)}
        className='report-modal' backdrop="static" >
        <Modal.Body className='logout-model'>
          <p className='logout-model-sure'>Are you sure you want <br />  to logout</p>
          <div className='d-flex justify-content-center'>
            <Button className='btn-confirm' onClick={logoutUser} disabled={isLoading}>
              {isLoading ? (
                <div className="loader">loading...</div>
              ) : (
                <p>Confirm</p>
              )}
            </Button>
            <Button className='btn-cancel' onClick={() => (setLogoutModal(false))}  >Cancel</Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={deleteAccount} onHide={() => setDeleteAccount(false)}
        className='report-modal' backdrop="static">
        <Modal.Body className='logout-model'>
          <p>Please enter your password to <br /> delete account</p>
          <Form.Group controlId="formBasicPassword"
            className="delete position-relative d-flex justify-content-center mb-4">
            {/* <i class="fa-solid fa-lock login_icon_wrap"></i> */}
            <Form.Control
              type={showPassword ? 'text' : 'password'}
              name="password"
              placeholder="Password"
              value={password}
              onChange={(e) => (setPassword(e.target.value))}
            />
            {showPassword ? (
              <i className="fa-solid fa-eye delete_wrap" onClick={handleHidePassword}></i>
            ) : (
              <i className="fa-solid fa-eye-slash delete_wrap" onClick={handleShowPassword}></i>
            )}

          </Form.Group>
          <div className='d-flex justify-content-center'>
            <Button className='btn-confirm' onClick={DeleteUser}>Confirm</Button>
            <Button className='btn-cancel' onClick={() => (setDeleteAccount(false),
              setPassword(''))}  >Cancel</Button>
          </div>
        </Modal.Body>
      </Modal>

      <ToastContainer />
    </>
  );
};

export default Inspector;